import React from "react"
import "./TeamProperties.scss"
import { Container } from "react-bootstrap"
import Slider from "@components/elements/Slider/Slider"
import CTAText from "@components/elements/CTAText/CTAText"
import { Link } from "gatsby"
import { propertiesRentURL, propertiesSaleURL } from "@lib/urls"
import { isEmpty } from "lodash"
import PropertyCard from "@components/PropertyCard/PropertyCard"
import useDeviceMedia from "@hooks/useDeviceMedia"

const TeamProperties = ({ team, properties, noNegotiaterProperties }) => {
  const { isTablet } = useDeviceMedia()

  let propertiesToDisplay = []

  if (isEmpty(properties)) {
    // If there are no properties, we want to display the noNegotiaterProperties only if the person is Loai Al Fakir
    if (isEmpty(noNegotiaterProperties) || team.slug !== "loai-al-fakir")
      return null
    propertiesToDisplay = noNegotiaterProperties
  } else {
    propertiesToDisplay = properties
  }

  const { name } = team
  return (
    <div className="team-properties-wrap section-p">
      <Container className="team-properties-container">
        <h2 className="title">{name}&apos;s Properties</h2>
        <div className="team-properties">
          {isTablet ? (
            <Slider size="lg">
              {propertiesToDisplay.map((property, index) => (
                <PropertyCard
                  key={property.id}
                  address={property.display_address}
                  ammenities={property.accommodation_summary}
                  description={property.description}
                  bathrooms={property.bathroom}
                  bedrooms={property.bedroom}
                  building={property.building}
                  image={property.images[0]}
                  images={property?.images}
                  price={property.price}
                  size={property.floorarea_min}
                  email={property?.crm_negotiator_id?.email}
                  crm_negotiator_id={property?.crm_negotiator_id?.id}
                  name={property?.crm_negotiator_id?.name}
                  index={index}
                  crmID={property?.crm_id}
                  bitrix_id={property?.extra?.bitrix_id}
                  slug={property?.slug}
                  searchType={property?.search_type}
                  imagetransforms={property?.ggfx_results}
                  strapiID={property?.strapi_id}
                  department={property?.department}
                  phone={property?.crm_negotiator_id?.phone}
                  noContact={!isTablet}
                  singnatureProp={property?.search_type === "sales" && property?.price >= 20000000 && property?.department === "residential" ? true : false}
                  link_to_employee={property?.link_to_employee}
                />
              ))}
            </Slider>
          ) : (
            <div className="team-properties-list">
              {propertiesToDisplay.map((property, index) => (
                <PropertyCard
                  key={property.id}
                  address={property.display_address}
                  ammenities={property.accommodation_summary}
                  description={property.description}
                  bathrooms={property.bathroom}
                  bedrooms={property.bedroom}
                  building={property.building}
                  email={property?.crm_negotiator_id?.email}
                  crm_negotiator_id={property?.crm_negotiator_id?.id}
                  name={property?.crm_negotiator_id?.name}
                  image={property.images[0]}
                  images={property?.images}
                  price={property.price}
                  size={property.floorarea_min}
                  index={index}
                  crmID={property?.crm_id}
                  bitrix_id={property?.extra?.bitrix_id}
                  slug={property?.slug}
                  searchType={property?.search_type}
                  imagetransforms={property?.ggfx_results}
                  strapiID={property?.strapi_id}
                  department={property?.department}
                  noContact={!isTablet}
                  singnatureProp={property?.search_type === "sales" && property?.price >= 20000000 && property?.department === "residential" ? true : false}
                  link_to_employee={property?.link_to_employee}
                />
              ))}
            </div>
          )}
        </div>
        <div className="divider" />
        <CTAText
          data={{
            cta: {
              cta_label: "View more properties",
              custom_link: propertiesSaleURL,
            },
            text: (
              <>
                View all properties <Link to={propertiesSaleURL}>for sale</Link>
                , <Link to={propertiesRentURL}>for rent</Link>, or off plan
              </>
            ),
          }}
        />
      </Container>
    </div>
  )
}

export default TeamProperties
